import { EnvironmentHelper } from "src/classes/EnvironmentHelper";
import { OrganisationConfig } from "src/interfaces/environment";

const CONFIG: OrganisationConfig = {
  NAME: "fixteamdrenthe",
  TITLE: "Fixteam Drenthe",
  API: null,
  WEBSOCKET: null,
  SHOW_HISTORY: true,
  QR_LOGIN: true,
  QR_REFRESH_RATE: 60000,
  QR_CHECK_RATE: 5000,
  MSAL: null,
  SUBFUNCTIONALITIES: ["VIDEO"],
  MAP: null,
};

const CONFIG_DEVELOPMENT: Partial<OrganisationConfig> = {
  TITLE: "Fixteam Drenthe | Development",
  API: "https://dev.api.epf.fixteamdrenthe.nl",
  MSAL: {
    clientId: "e7c625bb-3e5b-48a1-becf-883ebadcad9d",
    authority: "https://drentheepfdev.b2clogin.com/drentheepfdev.onmicrosoft.com/B2C_1_SignupAndSignin",
    knownAuthorities: ["https://drentheepfdev.b2clogin.com"],
  },
};

const CONFIG_TEST: Partial<OrganisationConfig> = {};
const CONFIG_STAGING: Partial<OrganisationConfig> = {};

const CONFIG_PRODUCTION: Partial<OrganisationConfig> = {};

export const ORGANISATION = EnvironmentHelper.GetEnvironment({
  CONFIG,
  CONFIG_DEVELOPMENT,
  CONFIG_TEST,
  CONFIG_STAGING,
  CONFIG_PRODUCTION,
});
